#AboutMeContainer {
  height: 100%;
  width: 100%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  position: absolute;
  backdrop-filter: blur(5px);
}

#AboutMeContainer * {
  margin-top: auto;
  margin-bottom: auto;
}

#AboutMeInnerContainer {
  height: 90%;
  width: 90%;
  background-color: rgba(41, 70, 70, 0.3);
  display: flex;
  flex-direction: column;
}

#AboutMeContainer h1 {
  font-size: 3em;
  align-self: center;
  margin-top: 10px;
}

#AboutMeContainer h1,
#AboutMe h3 {
  font-family: "Merriweather", serif;
  font-weight: bold;
  line-height: 1.5;
  color: rgb(240, 240, 240);
  align-self: center;
  width: 100px;
}

#AboutMe {
  padding: 15px;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  /* max-width: 900px; */
  overflow: hidden;
  border-radius: 5px;
}

#AboutMe .infoContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

#AboutMe .infoContainer .info {
  padding: 20px;
  width: 100%;
  max-height: 100%;
  overflow-y: overlay;
  margin: auto 0;
}

#AboutMe .me {
  width: 35%;
  display: flex;
  margin: 10px 20px;
  align-self: center;
}

#AboutMe .me img {
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
  height: auto;
  background-color: transparent;
}

.technologiesContainer {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  /* background-color: rgba(255, 255, 255, 0.2); */
  border-radius: 10px;
  width: 100%;
}

#AboutMe .technologies .technology {
  margin: 15px 0px;
  width: 100%;
  max-width: 1100px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.technologies .technology div {
  display: flex;
  align-items: inherit;
  flex-wrap: inherit;
}

.technologies .technology img {
  width: auto;
  height: 50px;
  margin: 10px 10px;
}

#AboutMe p {
  font-size: 1.4em;
  margin: 0 auto;
  color: rgb(240, 240, 240);
  font-family: "Merriweather", serif;
  font-weight: 100;
  line-height: 1.5;
  width: 100%;
  max-width: 1100px;
}

@media only screen and (max-width: 1050px) {
  #AboutMe {
    flex-direction: column;
  }

  #AboutMe h1 {
    font-size: 2rem;
  }

  #AboutMe p {
    font-size: 0.9rem;
  }
}

.technologyBackground {
  background-color: rgb(255, 255, 255, 1);
  padding: 5px;
  border-radius: 5px;
}

#AboutMe hr {
  width: 100%;
  margin: 25px 0px;
  border: 0;
  height: 2px;
  background-image: linear-gradient(
    to left,
    rgba(117, 117, 117, 0),
    rgba(117, 117, 117, 0.75),
    rgba(117, 117, 117, 0)
  );
}
