#SingleProjectOuterContainer {
  height: 100%;
  width: 95%;
  padding: 10px;
}

#SingleProjectContainer {
  top: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: auto;
  overflow-x: hidden;
}

#SingleProjectContainer h1 {
  text-align: center;
}

#SingleProjectContainer #SingleProject {
  margin-top: auto;
  margin-bottom: auto;
}

#SingleProject {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
}

#SingleProjectContainer h1 {
  font-size: 3em;
  margin: 10px;
  color: rgb(240, 240, 240);
}

.slideshow {
  width: 100%;
  height: 100%;
  /* height: auto; */
}

.slideshow .carousel-slider {
  max-height: 500px;
}

.slideshow .carousel-slider img {
  align-self: center;
  justify-self: center;
  width: auto !important;
  max-width: 100%;
  max-height: 500px;
}

.slide {
  display: flex;
}

.slide div {
  margin-top: auto;
  margin-bottom: auto;
}

.slideshow .thumbs-wrapper img {
  height: auto;
  max-height: 80px;
  width: auto !important;
  max-width: 100%;
  margin-left: 10px;
  margin-right: 10px;
}

.slideshow .thumbs-wrapper .thumb {
  margin: 0px 10px;
  height: 100%;
  width: auto !important;
}

.carousel .thumbs-wrapper {
  display: none;
}

.slideshow .carousel .control-prev.control-arrow:before {
  border-right: 8px solid rgb(62, 168, 124);
}

.slideshow .carousel .control-next.control-arrow:before {
  border-left: 8px solid rgb(62, 168, 124);
}

#SingleProjectContainer .projectInfo {
  margin: 15px 0;
  padding: 10px;
  padding-top: 0;
  width: 100%;
  border-radius: 10px;
}

.projectInfo p {
  font-size: 1.4em;
  color: rgb(240, 240, 240);
  font-family: "Merriweather", serif;
  font-weight: 100;
  line-height: 1.5;
}

#SingleProjectContainer .links {
  margin: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#SingleProjectContainer .links a {
  flex-wrap: wrap;
  margin: 10px 10px;
  color: rgb(240, 240, 240);
  text-decoration: none;
  padding: 10px;
  border: 2px solid rgb(240, 240, 240);
  border-radius: 10px;
  transition: all 0.2s;
}

.links a:hover {
  background-color: rgba(0, 255, 0, 0.2);
}

#SingleProjectContainer .mobile {
  display: none;
}

@media only screen and (max-width: 1050px) {
  #SingleProjectContainer h1 {
    font-size: 2rem;
  }

  .projectInfo p {
    font-size: 0.9rem;
  }

  #SingleProjectOuterContainer {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    position: absolute;
    height: 100%;
    width: 100vw;
    background-color: rgb(7, 55, 87);
    z-index: 1000000000;
    padding: 10px 10px;
  }

  #SingleProjectContainer {
    padding: 10px 10px;
    width: 90%;
  }

  #SingleProjectContainer .mobile {
    display: block;
    min-height: 75px;
    
  }
}

@media only screen and (max-height: 700px) {
  .slideshow .carousel-slider img {
    max-height: 500px;
  }
}

@media only screen and (max-height: 500px) {
  .slideshow .carousel-slider img {
    max-height: 350px;
  }
}

#SingleProjectOuterContainer hr {
  width: 100%;
  margin: 15px 0px;
  border: 0;
  height: 2px;
  background-image: linear-gradient(
    to left,
    rgba(117, 117, 117, 0),
    rgba(117, 117, 117, 0.75),
    rgba(117, 117, 117, 0)
  );
}

#SingleProjectContainer .mobile button {
  align-self: flex-start;
  margin: 5px 5%;
  border: 1px solid rgba(240, 240, 240, 0.2);
  text-decoration: none;
  font-size: 1.3rem;
  color: rgb(240, 240, 240);
  position: relative;
  z-index: 100000;
  background-color: rgba(201, 105, 105, 0.336);
  display: inline-block;
  padding: 10px;
  border-radius: 2px;
  transition: all 0.2s;
}

#SingleProjectContainer .mobile button:hover {
  background-color: rgba(201, 105, 105, 0.801);
  cursor: pointer;
}