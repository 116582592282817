#Home {
  position: fixed;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: 5px;
  height: 100vh;
  width: 100vw;
  overflow: auto;
}

#Home .content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  width: 90%;
}

/* MENU */

#Home .menuContainer {
  height: 75%;
  max-height: 600px;
  width: auto;

  width: 95%;
  max-width: 600px;
  background-color: rgba(41, 70, 70, 0.5);
  border-radius: 2px;
}

#Home .menuContainer hr {
  width: 100%;
  margin: 0px 0px;
  border: 0;
  height: 2px;
  background-image: linear-gradient(
    to left,
    rgba(117, 117, 117, 0),
    rgba(117, 117, 117, 0.75),
    rgba(117, 117, 117, 0)
  );
}

#Home .menuContainer a li,
#Home .menuContainer div li {
  padding: 0 20px;
  height: 75px;
  margin: 5px 0px;
  width: 100%;
  transition: all 0.5s linear;
}

#Home .menuContainer a li:hover,
#Home .menuContainer div li:hover {
  background: linear-gradient(
    90deg,
    rgba(194, 167, 46, 0.7) 0%,
    rgba(189, 157, 86, 0.5) 51%,
    rgba(179, 164, 96, 0.3) 100%
  );
}

#Home .menuContainer a,
#Home .menuContainer div {
  text-decoration: none;
  position: relative;
}

#Home .menuContainer div button {
  text-align: left;
  width: 100%;
  background: none;
  padding: 0;
}

#Home .menuContainer a *,
#Home .menuContainer div button * {
  color: rgb(240, 240, 240);
  font-size: 1.5rem;
}

.links {
  margin: 10% 0;
}

/* TEXT */

#Home .textContainer {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  padding-bottom: 5%;
  width: 700px;
}

#Home .textContainer .text {
  padding-left: 10px;
}

#Home .textContainer h1 {
  color: rgba(255, 255, 255, 0);
  -webkit-text-stroke-color: rgb(240, 240, 240);
  -webkit-text-stroke-width: 1px;
  font-size: 6rem;
  margin: 5px;
  font-family: Serif, "Times New Roman";
}

#Home .textContainer h3 {
  color: rgb(158, 158, 158);
  font-size: 2rem;
  margin: 5px;
}

@media only screen and (max-width: 1333px) {
  .content {
    margin: auto;
  }

  .content .menuContainer,
  .content .textContainer {
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 670px) {
  #Home .menuContainer {
    background-color: rgba(41, 70, 70, 0.3);
  }

  #Home .textContainer {
    width: 370px;
  }
  #Home .textContainer h1 {
    font-size: 3rem;
  }

  #Home .textContainer h3 {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 410px) {
  #Home .textContainer {
    width: 270px;
  }
  #Home .textContainer h1 {
    font-size: 2rem;
  }

  #Home .textContainer h3 {
    font-size: 1rem;
  }
}
