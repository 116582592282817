#MyProjectsContainer {
  height: 100%;
  width: 100%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  position: absolute;
  backdrop-filter: blur(5px);
}

/* #MyProjectsContainer * {
  margin-top: auto;
  margin-bottom: auto;
} */

#MyProjects {
  padding: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: auto;
  width: 90%;
  height: 90%;
  background-color: rgba(41, 70, 70, 0.3);
  border-radius: 5px;
}

#MyProjectsContainer h1 {
  font-size: 3em;
  color: rgb(240, 240, 240);
  align-self: center;
}

#MyProjects > h3 {
  color: rgb(185, 185, 185);
  margin-bottom: 10px;
  align-self: center;
}

#Projects {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

#Projects .picker {
  width: 40%;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

#Projects .preview {
  width: 60%;
  height: 100%;
  margin: auto;
}

.left {
  text-align: left;
  align-self: flex-start;
}

.picker h3 {
  font-size: 2.2em;
  color: rgba(255, 255, 255, 0.5);
  transition: all 0.8s;
}

#Projects .picker a {
  align-self: center;
  width: 90%;
  text-decoration: none;
  font-size: 1.5em;
  color: rgb(158, 158, 158);
  background-color: transparent;
  outline: none;
  transition: all 0.4s;
  /* width: max-content; */
  padding: 0 10px;
  border-bottom: 1px solid transparent;
  border-top: 1px solid transparent;
  margin: 5vh 0px;
  display: flex;
  flex-direction: column;
}

#Projects .picker a:hover {
  transform: scale(1.025);
  cursor: pointer;
  color: rgb(185, 185, 185);
  background: linear-gradient(
    90deg,
    rgba(46, 115, 194, 0.4) 0%,
    rgba(50, 79, 157, 0.3) 51%,
    rgba(58, 100, 164, 0.2) 100%
  );
  border-radius: 5px;
}

#Projects .picker .projectActive {
  transform: scale(1.025);
  color: rgb(240, 240, 240);
  background: linear-gradient(
    90deg,
    rgba(46, 115, 194, 0.8) 0%,
    rgba(50, 79, 157, 0.6) 51%,
    rgba(58, 100, 164, 0.4) 100%
  );
  border-radius: 5px;
}

.picker .projectActive h3 {
  color: rgb(240, 240, 240);
}

.picker p,
.picker h3 {
  margin: 2px;

  text-align: inherit;
}

.picker p {
  font-size: 0.8em;
}

.picker a:hover h3 {
  color: rgb(185, 185, 185);
}

@media only screen and (max-width: 1050px) {
  .picker a,
  .picker h3 {
    color: rgb(240, 240, 240);
  }
  #MyProjects h1 {
    font-size: 2rem;
  }
  .picker h3 {
    font-size: 1.3em;
  }

  .picker p {
    font-size: 0.65em;
    max-width: 300px;
    margin: 0px;
  }

  #Projects .picker {
    width: 100%;
  }

  #Projects .preview {
    width: 0%;
  }
}

#Projects .preview h2 {
  color: rgb(240, 240, 240);
  text-align:  center;
  margin: 10px;
}